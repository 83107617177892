import { useEffect, useState } from "react";
import Table from "../components/Table";
import "../assets/styles/Form.css";
import { getSpeakers } from "../services/speakers";
import { createMagazines, deleteMagazine, getMagazines } from "../services/magazine";
import { toast } from "react-toastify";
import ImagePreview from "../components/ImagePreview";

function Magazine() {
  const [isShowForm, setShowForm] = useState(false);

  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    pdf: [],
    img: [],
  });

  const columns = ["name", "img", "pdf"];

  const getData = async () => {
    const { data } = await getMagazines();
    setData(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: [...files] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);

    const newFormData = new FormData();

    for (const key in formData) {
      if (key === "img") {
        console.log(formData[key]);
        formData[key]?.forEach((file) => newFormData.append("img", file));
      } else if (key === "pdf") {
        formData[key]?.forEach((file) => newFormData.append("pdf", file));
      } else {
        newFormData.append(key, formData[key]);
      }
    }

    if (!formData.name || !formData.img.length || !formData.pdf.length) {
      toast.error("Please fill the form");
    } else {
      const { data } = await createMagazines(newFormData);
      if (data.message === "Created successfully") {
        toast.success("Created succesfully");
        setFormData({
          name: "",
          img: [],
          pdf: [],
        });
        setShowForm(false);
        const { data } = await getMagazines();
        console.log(data);
        setData(data.data);
      }
    }
  };

  const handleRemoveImage = (index) => {
    const img = formData.img.filter((item, i) => i !== index)
    setFormData((prev) => ({
      ...prev,
      img
    }))
  }

  const handleDelete = async (id) => {
    const { data } = await deleteMagazine(id);
    if (data.message === "Magazine deleted succesfully") {
      toast.success("Magazine deleted succesfully");
    }
    getData();
  };

  const handleSetForm = (e) => {
    e.preventDefault();
    setShowForm(false)
  }

  return (
      <div className="main-content">
        <h2 style={{ textAlign: "start", marginLeft: '20px' }}>Magazines</h2>
        <button
          style={{ display: isShowForm ? "none" : "block", margin: '0px 15px 10px 20px' }}
          onClick={() => setShowForm(true)}
          className="add-btn"
        >
          Add Magazine
        </button>
        <form
          onSubmit={handleSubmit}
          style={{ display: !isShowForm ? "none" : "block", textAlign: 'start' }}
        >
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            style={{ marginBottom: '15px' }}
          />

          <label style={{ marginTop: "10px" }}>Image</label>
          <input type="file" name="img" multiple onChange={handleChange} style={{ marginBottom: '15px' }} />

          <label style={{ marginTop: "10px" }}>Pdf</label>
          <input type="file" name="pdf" multiple onChange={handleChange} />

          <ImagePreview files={formData.img} handleRemoveImage={handleRemoveImage} />

          <div className="form-btns" style={{ display: "flex", gap: 10 }}>
            <button onClick={handleSetForm} className="form-btn">
              Cancel
            </button>
            <button className="form-btn" type="submit">
              Submit
            </button>
          </div>

        </form>
        <Table columns={columns} data={data} handleDelete={handleDelete} />
      </div>

  );
}

export default Magazine;
