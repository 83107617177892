import axios from "axios";
import { baseUrl } from "../config";

export const addCareer = (newFormData) => {
  return axios.post(`${baseUrl}/api/v1/career`, newFormData);
};

export const getCareer = () => {
  return axios.get(`${baseUrl}/api/v1/career`);
}

export const deleteCareer = (id) => {
   return axios.delete(`${baseUrl}/api/v1/career`, { data: { id } });
}