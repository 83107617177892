import axios from "axios";
import { baseUrl } from "../config";

export const createMagazines = (newFormData) => {
    return axios.post(`${baseUrl}/api/v1/magazine`,  newFormData );
}

export const getMagazines = () => {
    return axios.get(`${baseUrl}/api/v1/magazine`);
}
  
export const deleteMagazine = (id) => {
    return axios.delete(`${baseUrl}/api/v1/magazine`, { data: { id } });
}