import React, { useEffect, useState } from 'react';
import "../assets/styles/LoginPage.css"; // Assuming you have a separate CSS file
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo.png'
import { login } from '../services/login';
import { toast } from 'react-toastify';
import { authCheck } from '../services/authorization';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    console.log('Username:', username, 'Password:', password);
    try {
      const { data } = await login(username, password);
      if(data.token) {
        localStorage.setItem('token', data.token);
        navigate('/events');
      }
    } catch(e) {
        toast.error("Login failed");
    }
    
  };

  const [authorized, setAuthorized] = useState(false);

  const checkAuthStatus = async() => {
    try {
      const { data } = await authCheck();
      return data?.message;
    } catch(e) {
      console.log(e);
    }
  }
 
  useEffect(() => {
    checkAuthStatus().then((res) => {
      console.log(res);
      if(res === "Authorized") {
        navigate('/events');
      } else {
        navigate('/');
      }
    })
  }, []);

  return (
    <div className="login-container">
      <div className="login-card">
        {/* <h3>Login</h3> */}
        <img src={Logo} alt=' ' style={{ width: '150px', marginBottom: 30 }} />
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Username:
              <input type="text" value={username} onChange={handleUsernameChange} />
            </label>
          </div>
          <div>
            <label>
              Password:
              <input type="password" value={password} onChange={handlePasswordChange} />
            </label>
          </div>
          <div>
            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
