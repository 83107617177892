import { useEffect, useState } from "react";
import Table from "../components/Table";
import "../assets/styles/Form.css";
import { toast } from "react-toastify";
import { addEvent, deleteEvent, getEvents } from "../services/events";
import ImagePreview from "../components/ImagePreview";

function Events() {
  const [isShowForm, setShowForm] = useState(false);

  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    location: "",
    category: "",
    images: [],
    description: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    startTimePeriod: "AM",
    endTimePeriod: "AM",
  });

  const columns = [
    "name",
    "location",
    "category",
    "description",
    "start",
    "end",
    // 'images'
  ];

  const getData = async () => {
    const { data } = await getEvents();
    setData(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: [...files] }));
    } else {
      if (name === "startTime" || name === "endTime") {
        const timeValue =
          value.includes("AM") || value.includes("PM")
            ? value.split(" ")[0] // Only update the time part
            : value;
        setFormData((prev) => ({ ...prev, [name]: timeValue }));
      } else {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);

    const newFormData = new FormData();

    for (const key in formData) {
      if (key === "images") {
        console.log(formData[key]);
        formData[key]?.forEach((file) => newFormData.append("images", file));
      } else {
        newFormData.append(key, formData[key]);
      }
    }

    if (
      !formData.name ||
      !formData.description ||
      !formData.category ||
      !formData.startDate ||
      !formData.endDate ||
      !formData.location ||
      !formData.startTime ||
      !formData.endTime ||
      !formData.images.length ||
      !formData.startTimePeriod ||
      !formData.endTimePeriod
    ) {
      console.log("error..");
      toast.error("Please fill the form");
    } else {
      console.log(newFormData);
      const { data } = await addEvent(newFormData);
      if (data.message === "Event created successfully") {
        toast.success("Event created successfully");
        setFormData({
          name: "",
          location: "",
          category: "",
          images: [],
          description: "",
          startDate: "",
          endDate: "",
          startTime: "",
          endTime: "",
          startTimePeriod: "AM",
          endTimePeriod: "AM",
        });
        setShowForm(false);
        const { data } = await getEvents();
        console.log(data);
        setData(data.data);
      }
    }
  };

  const handleDelete = async (id) => {
    const { data } = await deleteEvent(id);
    if (data.message === "Event deleted successully") {
      toast.success("Event deleted successully");
    }
    getData();
  };

  const handleRemoveImage = (index) => {
    const images = formData.images.filter((item, i) => i !== index)
    setFormData((prev) => ({
      ...prev,
      images
    }))
  }

  const handleSetForm = (e) => {
    e.preventDefault();
    setShowForm(false)
  }

  return (
    // <div className="main-content">
      <div className="main-content">
        <h2 style={{ textAlign: "start", marginLeft: '20px' }}>Events</h2>
        <button
          style={{ display: isShowForm ? "none" : "block", margin: '0px 15px 10px 20px' }}
          onClick={() => setShowForm(true)}
          className="add-btn"
        >
          Add Event
        </button>
        <form
          onSubmit={handleSubmit}
          style={{ display: !isShowForm ? "none" : "grid" }}
        >
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={formData.location}
            onChange={handleChange}
          />
          <input
            type="text"
            name="category"
            placeholder="Category"
            value={formData.category}
            onChange={handleChange}
          />
          <textarea
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            placeholder="Start Date"
          />
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
          />
          <input
            type="text"
            name="startTime"
            placeholder="HH:MM"
            value={formData.startTime}
            onChange={handleChange}
            pattern="(?:[01][0-9]|2[0-3]):[0-5][0-9]" // Validates time in HH:MM format
          />
          <select
            name="startTimePeriod"
            value={formData.startTimePeriod}
            onChange={handleChange}
          >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
          <input
            type="text"
            name="endTime"
            placeholder="HH:MM"
            value={formData.endTime}
            onChange={handleChange}
            pattern="(?:[01][0-9]|2[0-3]):[0-5][0-9]" // Validates time in HH:MM format
          />
          <select
            name="endTimePeriod"
            value={formData.endTimePeriod}
            onChange={handleChange}
          >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
          <input type="file" name="images" multiple onChange={handleChange} />
          <ImagePreview files={formData.images} handleRemoveImage={handleRemoveImage} />
          <div className="form-btns" style={{ display: "flex", gap: 10 }}>
            <button onClick={handleSetForm} className="form-btn">
              Cancel
            </button>
            <button className="form-btn" type="submit">
              Submit
            </button>
          </div>
          
        </form>
        <Table columns={columns} data={data} handleDelete={handleDelete} />
      </div>

    // </div>
  );
}

export default Events;
