import React, { useEffect, useState } from 'react';
import '../assets/styles/Dashboard.css'; // Assuming you have a separate CSS file
import Sidebar from '../components/Sidebar';
import Table from '../components/Table';
import { authCheck } from '../services/authorization';
import { useNavigate } from 'react-router-dom';


function DashboardPage({ Component }) { 

  const navigate = useNavigate();

  const [authorized, setAuthorized] = useState(false);

  const checkAuthStatus = async() => {
    try {
      const { data } = await authCheck();
      return data?.message;
    } catch(e) {
      console.log(e);
    }
  }
 
  useEffect(() => {
    checkAuthStatus().then((res) => {
      console.log(res);
      if(res === "Authorized") {
        setAuthorized(true);
      } else {
        navigate('/');
      }
    })
  }, []);

  return (
    <div className="dashboard">
      <Sidebar />
      {authorized && Component}
    </div>
  );
}

export default DashboardPage;
