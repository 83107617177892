import logo from './logo.svg';
import './App.css';
import LoginPage from './pages/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DashboardPage from './pages/Dashboard';
import Events from './pages/Events';
import Gallery from './pages/Gallery';
import Speakers from './pages/Speakers';
import Magazine from './pages/Magazine';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Bookings from './pages/Bookings';
import ContactUs from './pages/ContactUs';
import Careers from './pages/Careers';
import Jobs from './pages/Jobs';


function App() {
  return (
    <div className="App">
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        width="400px"
        // limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
      />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/events' element={<DashboardPage Component={<Events /> } />} />
          <Route path='/gallery' element={<DashboardPage Component={<Gallery /> } />} />
          <Route path='/speakers' element={<DashboardPage Component={<Speakers /> } />} />
          <Route path='/magazines' element={<DashboardPage Component={<Magazine /> } />} />
          <Route path='/bookings' element={<DashboardPage Component={<Bookings /> } />} />
          <Route path='/contacts' element={<DashboardPage Component={<ContactUs /> } />} />
          <Route path='/careers' element={<DashboardPage Component={<Careers /> } />} />
          <Route path='/jobs' element={<DashboardPage Component={<Jobs /> } />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
