import axios from "axios";
import { baseUrl } from "../config";

export const addEvent = (newFormData) => {
  return axios.post(`${baseUrl}/api/v1/event`, newFormData);
};

export const getEvents = () => {
  return axios.get(`${baseUrl}/api/v1/event`);
}

export const deleteEvent = (id) => {
   return axios.delete(`${baseUrl}/api/v1/event`, { data: { id } });
}