import React, { useState } from "react";
import Modal from "react-modal";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { baseUrl } from "../config";

Modal.setAppElement("#root"); // Prevents screen readers from reading background content

const PdfModal = ({ isOpen, onRequestClose, pdfFile }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="PDF Viewer"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "80%",
        },
      }}
    >
      {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.10.377/es5/build/pdf.worker.min.js`}>
                <Viewer
                    fileUrl={pdfFile}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </Worker> */}

        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(
            pdfFile
          )}&embedded=true`}
          title="PDF Viewer"
          style={{ marginTop: "0px" }}
          width="100%"
          height="100%"
        ></iframe>

    </Modal>
  );
};

export default PdfModal;
