import { useEffect, useState } from "react";
import Table from "../components/Table";
import "../assets/styles/Form.css";
import { toast } from "react-toastify";
import { addEvent, deleteEvent, getEvents } from "../services/events";
import ImagePreview from "../components/ImagePreview";
import { getBookings } from "../services/bookings";

function Bookings() {

  const [data, setData] = useState([]);

  const columns = [
    "name",
    "email",
    "phone",
    "address",
    "eventName",
    // 'images'
  ];

  const getData = async () => {
    const { data } = await getBookings();
    setData(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    // <div className="main-content">
      <div className="main-content">
        <h2 style={{ textAlign: "start", marginLeft: '20px' }}>Bookings</h2>
        
        <Table columns={columns} data={data} />
      </div>

    // </div>
  );
}

export default Bookings;
