import { useEffect, useState } from "react";
import Table from "../components/Table";
import "../assets/styles/Form.css";
import {
  createSpeakers,
  deleteSpeaker,
  getSpeakers,
} from "../services/speakers";
import { toast } from "react-toastify";
import ImagePreview from "../components/ImagePreview";

function Speakers() {
  const [isShowForm, setShowForm] = useState(false);

  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    img: [],
  });

  const columns = ["name", "designation", "image"];

  const getData = async () => {
    const { data } = await getSpeakers();
    setData(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: [...files] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);

    const newFormData = new FormData();

    for (const key in formData) {
      if (key === "img") {
        console.log(formData[key]);
        formData[key]?.forEach((file) => newFormData.append("img", file));
      } else {
        newFormData.append(key, formData[key]);
      }
    }

    if (!formData.name || !formData.designation || !formData.img.length) {
      toast.error("Please fill the form");
    } else {
      const { data } = await createSpeakers(newFormData);
      if (data.message === "Created successfully") {
        toast.success("Created successfully");
        setFormData({
          name: "",
          img: [],
        });
        setShowForm(false);
        const { data } = await getSpeakers();
        console.log(data);
        setData(data.data);
      }
    }
  };

  const handleRemoveImage = (index) => {
    const img = formData.img.filter((item, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      img,
    }));
  };

  const handleDelete = async (id) => {
    const { data } = await deleteSpeaker(id);
    if (data.message === "Speaker deleted successully") {
      toast.success("Speaker deleted successully");
    }
    getData();
  };

  const handleSetForm = (e) => {
    e.preventDefault();
    setShowForm(false)
  }

  return (
    <div className="main-content">
      <h2 style={{ textAlign: "start", marginLeft: "20px" }}>Our Speakers</h2>
      <button
        style={{
          display: isShowForm ? "none" : "block",
          margin: "0px 15px 10px 20px",
        }}
        onClick={() => setShowForm(true)}
        className="add-btn"
      >
        Add Speaker
      </button>
      <form
        onSubmit={handleSubmit}
        style={{ display: !isShowForm ? "none" : "block" }}
      >
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
        />

        <input
          type="text"
          name="designation"
          placeholder="Designation"
          value={formData.designation}
          onChange={handleChange}
        />

        <input type="file" name="img" single onChange={handleChange} />

        <ImagePreview
          files={formData.img}
          handleRemoveImage={handleRemoveImage}
        />
        <div className="form-btns" style={{ display: "flex", gap: 10 }}>
          <button onClick={handleSetForm} className="form-btn">
            Cancel
          </button>
          <button className="form-btn" type="submit">
            Submit
          </button>
        </div>
      </form>
      <Table columns={columns} data={data} handleDelete={handleDelete} />
    </div>
  );
}

export default Speakers;
