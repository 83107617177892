import { useEffect, useState } from "react";
import { baseUrl } from "../config";

function ImagePreview({ files, data, handleRemoveImage }) {
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    console.log(files);
    const newImageUrls = files.map((file) => {
      return URL.createObjectURL(file);
    });
    setImagePreviews(newImageUrls);
  }, [files]);

  return (
    <div className="image-preview">
      {imagePreviews.map((imagePreview, index) => (
        <div key={index} style={{ position: "relative" }}>
          <img
            key={index}
            src={imagePreview}
            alt="preview"
            style={{ width: "auto", height: "150px", marginLeft: "10px" }}
          />
          <svg
            onClick={() => handleRemoveImage(index)}
            style={{ position: 'absolute', top: 10, right: 10 }}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="30"
            height="30"
            viewBox="0 0 48 48"
          >
            <path
              fill="#F44336"
              d="M21.5 4.5H26.501V43.5H21.5z"
              transform="rotate(45.001 24 24)"
            ></path>
            <path
              fill="#F44336"
              d="M21.5 4.5H26.5V43.501H21.5z"
              transform="rotate(135.008 24 24)"
            ></path>
          </svg>
        </div>
      ))}
      <div style={{ marginTop: '20px' }}>
        {
          data?.length > 0 && data.map((item, index) => (
            <img
              key={index}
              src={`${baseUrl}/api/v1/image/${item}`}
              alt="preview"
              style={{ width: "auto", height: "150px", marginLeft: "10px" }}
            />
          ))
        }
      </div>
    </div>
  );
}

export default ImagePreview;
