import axios from "axios";
import { baseUrl } from "../config";

export const createSpeakers = (newFormData) => {
    return axios.post(`${baseUrl}/api/v1/our-speakers`,  newFormData );
}

export const getSpeakers = () => {
    return axios.get(`${baseUrl}/api/v1/our-speakers`);
}
  
export const deleteSpeaker = (id) => {
    return axios.delete(`${baseUrl}/api/v1/our-speakers`, { data: { id } });
}