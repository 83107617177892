import { useEffect, useState } from "react";
import Table from "../components/Table";
import "../assets/styles/Form.css";
import { toast } from "react-toastify";
import { addEvent, deleteEvent, getEvents } from "../services/events";
import ImagePreview from "../components/ImagePreview";
import { addCareer, deleteCareer, getCareer } from "../services/careers";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function Careers() {
  const [isShowForm, setShowForm] = useState(false);

  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    department: "",
    role: "",
    start: "",
    description: "",
  });

  const columns = ["department", "role", "joining", "description"];

  const getData = async () => {
    const { data } = await getCareer();
    setData(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: [...files] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleTextEditor = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);

    if (
      !formData.department ||
      !formData.role ||
      !formData.start ||
      !formData.description
    ) {
      console.log("error..");
      toast.error("Please fill the form");
    } else {
      const { data } = await addCareer(formData);
      if (data.message === "Career created succesfully") {
        toast.success("Event created successfully");
        setFormData({
          department: "",
          role: "",
          start: "",
          description: "",
        });
        setShowForm(false);
        const { data } = await getCareer();
        console.log(data);
        setData(data.data);
      }
    }
  };

  const handleDelete = async (id) => {
    const { data } = await deleteCareer(id);
    if (data.message === "Career Deleted Successfully") {
      toast.success("Career Deleted Successfully");
    }
    getData();
  };

  const handleRemoveImage = (index) => {
    const images = formData.images.filter((item, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      images,
    }));
  };

  const handleSetForm = (e) => {
    e.preventDefault();
    setShowForm(false)
  }

  return (
    // <div className="main-content">
    <div className="main-content">
      <h2 style={{ textAlign: "start", marginLeft: "20px" }}>Careers</h2>
      <button
        style={{
          display: isShowForm ? "none" : "block",
          margin: "0px 15px 10px 20px",
        }}
        onClick={() => setShowForm(true)}
        className="add-btn"
      >
        Add Career
      </button>
      <form
        onSubmit={handleSubmit}
        style={{ display: !isShowForm ? "none" : "grid" }}
      >
        <input
          type="text"
          name="department"
          placeholder="Department"
          value={formData.name}
          onChange={handleChange}
        />
        <input
          type="text"
          name="role"
          placeholder="Role"
          value={formData.location}
          onChange={handleChange}
        />
        <input
          type="text"
          name="start"
          placeholder="Start"
          value={formData.start}
          onChange={handleChange}
          style={{ maxHeight: '40px' }}
        />
        {/* <textarea
          name="description"
          placeholder="Description"
          value={formData.description}
          onChange={handleChange}
        ></textarea> */}

        <ReactQuill
          value={formData.description}
          onChange={handleTextEditor}
          placeholder="Description"
          // style={{  border: '1px solid black' }}
        />

        <div className="form-btns" style={{ display: "flex", gap: 10 }}>
          <button onClick={handleSetForm} className="form-btn">
            Cancel
          </button>
          <button className="form-btn" type="submit">
            Submit
          </button>
        </div>
      </form>
      <Table columns={columns} data={data} section={'Careers'} handleDelete={handleDelete} />
    </div>

    // </div>
  );
}

export default Careers;
