import { useState } from "react";
import "../assets/styles/Table.css";
import { baseUrl } from "../config";
import PdfModal from "./PdfModal";

function Table({ columns, data, section, handleDelete }) {

  const [isOpen, setOpen] = useState(false)
  const [file, setFile] = useState("");

  console.log(data);
  console.log(columns);

  function formatDate(dateInput) {
    const date = new Date(dateInput); // Ensure date is a Date object
    const day = date.getDate();
    const options = { year: "numeric", month: "long", day: "numeric" }; // Define options for formatting

    // Determine the correct ordinal suffix
    const daySuffix =
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th";

    // Use toLocaleDateString with options, and replace the day number with the number + suffix
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .replace(/\d+/, day + daySuffix);

    console.log(formattedDate); // Output the formatted date for debugging
    return formattedDate;
  }

  const handleShowPdf = (file) => {
    setFile(file);
    console.log(file);
    setOpen(true)
    console.log('open')
  }

  const handleCloseModal = () => {
    setOpen(false);
  };


  return (
    <>
      <PdfModal isOpen={isOpen} onRequestClose={handleCloseModal} pdfFile={file} />
      <div className="table-container">
      <table>
        <thead>
          <tr>
            {columns.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
            {!columns.includes("eventName") && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index}>
                {columns.includes("name") && <td>{item.name}</td>}
                {columns.includes("email") && <td>{item.email}</td>}
                {columns.includes("phone") && <td>{item.phone}</td>}
                {columns.includes("department") && <td>{item.department}</td>}
                {columns.includes("role") && <td>{item.role}</td>}
                {columns.includes("resume") && (
                  <td>
                    <button style={{ width: "100px" }} onClick={() => handleShowPdf(`${baseUrl}/api/v1/image/${item.resume}`)}>View</button>
                  </td>
                )}
                {columns.includes("address") && <td>{item.address}</td>}
                {columns.includes("message") && <td>{item.message}</td>}
                {columns.includes("eventName") && <td>{item.eventName}</td>}
                {columns.includes("location") && <td>{item.location}</td>}
                {columns.includes("category") && <td>{item.category}</td>}
                {columns.includes("joining") && <td>{item.start}</td>}
                {columns.includes("description") && 
                 ( section === "Careers" ? <td dangerouslySetInnerHTML={{ __html: item.description }}></td> : <td>{item.description}</td> )}
                {columns.includes("designation") && <td>{item.designation}</td>}
                {columns.includes("start") && (
                  <td>
                    {formatDate(item.startDate)} {item.startTime}
                  </td>
                )}
                {columns.includes("end") && (
                  <td>
                    {formatDate(item.endDate)}
                    {item.endTime}
                  </td>
                )}
                {columns.includes("image") && (
                  <td>
                    <img
                      src={`${baseUrl}/api/v1/image/${item.img}`}
                      style={{ width: "80px" }}
                      alt=" "
                    />
                  </td>
                )}
                {columns.includes("img") && (
                  <td>
                    <img
                      src={`${baseUrl}/api/v1/image/${item.img}`}
                      style={{ width: "80px" }}
                      alt=" "
                    />
                  </td>
                )}
                {columns.includes("pdf") && (
                  <td>
                    <button className="form-btn" onClick={() => handleShowPdf(`${baseUrl}/api/v1/image/${item.pdf}`)}>View pdf</button>
                  </td>
                )}
                {!columns.includes("eventName") && (
                  <td>
                    {/* <input type="checkbox" /> */}
                    {/* <button> */}
                    <svg
                      onClick={() => handleDelete(item._id)}
                      style={{ cursor: "pointer" }}
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                    </svg>
                    {/* </button> */}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
    </>
  );
}

export default Table;
