import { useEffect, useState } from "react";
import Table from "../components/Table";
import "../assets/styles/Form.css";
import { toast } from "react-toastify";
import { addEvent, deleteEvent, getEvents } from "../services/events";
import ImagePreview from "../components/ImagePreview";
import { getBookings } from "../services/bookings";
import { getContactus } from "../services/contactUs";
import * as XLSX from 'xlsx';

function ContactUs () {

  const [data, setData] = useState([]);

  const columns = [
    "name",
    "email",
    "phone",
    "message",
    // 'images'
  ];

  const getData = async () => {
    const { data } = await getContactus();
    console.log(data);
    setData(data?.data);
  };

  const downloadCSV = (data, fileName) => {
    console.log(data);
    // Create a new workbook and add a worksheet
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Write workbook and export to CSV
    XLSX.writeFile(wb, `${fileName}.csv`);
};

  useEffect(() => {
    getData();
  }, []);

  return (
    // <div className="main-content">
      <div className="main-content" style={{ textAlign: 'end' }}>
        <h2 style={{ textAlign: "start", marginLeft: '20px' }}>Contact Us</h2>
        <button style={{ width: '150px', marginBottom: "10px", marginRight: '20px' }} onClick={() => downloadCSV(data, 'download')}>Download to csv</button>
        <Table columns={columns} data={data} />
      </div>

    // </div>
  );
}

export default ContactUs;
