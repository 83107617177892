import { useEffect, useState } from "react";
import Logo from "../assets/images/logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import "../assets/styles/Dashboard.css";
import Menu from "../assets/images/menu.png";

function Sidebar() {
  const location = useLocation();

  const [selected, setSelected] = useState();

  const navigate = useNavigate();

  const [isMobile, setMobile] = useState(false);

  const sidebars = [
    "Events",
    "Gallery",
    "Speakers",
    "Magazines",
    "Bookings",
    "Contacts",
    "Careers",
    "Jobs",
  ];

  const handleResonsive = () => {
    const width = window.innerWidth;
    console.log(width);
    if (width < 600) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }

  useEffect(() => {
    handleResonsive();
  }, []);

  useEffect(() => {
    console.log(isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (location.pathname) {
      console.log(location.pathname);

      const sidebar = sidebars.find(
        (item, index) => item.toLowerCase() === location.pathname.split("/")[1]
      );

      console.log(sidebar);
      setSelected(sidebar);
    }
  }, [location.pathname]);

  const handleSelect = (item) => {
    setSelected(item);
    navigate(`/${item.toLowerCase()}`);
    handleResonsive();
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <>
      <div className="sidebar"  style={{ display: isMobile ? "none" : "block", }}>
        <div style={{ padding: "5px 20px 10px" }}>
          <img
            src={Logo}
            alt=" "
            style={{ width: "100%", mixBlendMode: "color-dodge" }}
          />
        </div>
        <ul>
          {sidebars.map((item, index) => {
            return (
              <li
                onClick={() => handleSelect(item)}
                style={{ background: item === selected ? "#1abc9c" : "" }}
                key={index}
              >
                {item}
              </li>
            );
          })}
        </ul>
        <button className="logout-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <div style={{ position: 'absolute',  }}>
        <img src={Menu} alt=" " style={{ width: '50px', height: '50px', cursor: 'pointer' }} onClick={() => setMobile(!isMobile)} />
      </div>
    </>
  );
}

export default Sidebar;
