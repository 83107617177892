import { useEffect, useState } from "react";
import Table from "../components/Table";
import "../assets/styles/Form.css";
import { toast } from "react-toastify";
import { addEvent, deleteEvent, getEvents } from "../services/events";
import ImagePreview from "../components/ImagePreview";
import { getJobs } from "../services/jobs";

function Jobs () {
  const [isShowForm, setShowForm] = useState(false);

  const [data, setData] = useState([]);

  

  const columns = [
    "name",
    "email",
    "phone",
    "department",
    "role",
    "resume"
    // 'images'
  ];

  const getData = async () => {
    const { data } = await getJobs();
    setData(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    // <div className="main-content">
      <div className="main-content">
        <h2 style={{ textAlign: "start", marginLeft: '20px' }}>Jobs</h2>
    
        <Table columns={columns} data={data} />
      </div>

    // </div>
  );
}

export default Jobs;
