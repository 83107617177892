import { useEffect, useState } from "react";
import Table from "../components/Table";
import "../assets/styles/Form.css";
import ImagePreview from "../components/ImagePreview";
import { addGallery, getGallery } from "../services/gallery";
import { toast } from "react-toastify";

function Gallery() {
  const [data, setData] = useState([]);

  const [isShowForm, setShowForm] = useState(false);

  const [formData, setFormData] = useState({
    images: [],
  });

  const handleSetForm = (e) => {
    e.preventDefault();
    setShowForm(false)
  }

  const getData = async () => {
    const { data } = await getGallery();
    setData(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: [...files] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    console.log(formData);

    const newFormData = new FormData();

    for (const key in formData) {
      if (key === "images") {
        console.log(formData[key]);
        formData[key]?.forEach((file) => newFormData.append("images", file));
      } else {
        newFormData.append(key, formData[key]);
      }
    }

    if (!formData.images.length) {
      toast.error("Please fill the form");
    } else {
      const { data } = await addGallery(newFormData);
      if(data.message === "Uploaded successfully") {
        toast.success("Uploaded successfully");
        setFormData({ images: [] });
      }
      setShowForm(false);
      getData();
    }

  };

  const handleRemoveImage = (index) => {
    const images = formData.images.filter((item, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      images,
    }));
  };

  return (
    <div className="main-content">
      <h2 style={{ textAlign: "start" }}>Gallery</h2>
      <button
        style={{ display: isShowForm ? "none" : "block" }}
        onClick={() => setShowForm(true)}
        className="add-btn"
      >
        Add
      </button>
      <form
        onSubmit={handleSubmit}
        style={{ display: !isShowForm ? "none" : "block" }}
      >
        <input type="file" name="images" multiple onChange={handleChange} />

        <ImagePreview
          files={formData.images}
          handleRemoveImage={handleRemoveImage}
        />

        <div className="form-btns" style={{ display: "flex", gap: 10 }}>
          <button onClick={handleSetForm} className="form-btn">
            Cancel
          </button>
          <button className="form-btn" type="submit">
            Submit
          </button>
        </div>
      </form>
      {!isShowForm && <ImagePreview files={formData.images} data={data} />}
    </div>
  );
}

export default Gallery;
